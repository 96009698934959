
import { computed, defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';

/** Interfaces */
import { ValidateAccessFormI } from '@/modules/coupon/interfaces/digital-coupon.interface';

/** Schemas */
import { schemaCouponValidationForm } from '@/validations/coupon-validation';

export default defineComponent({
  name: 'ValidateAccess',
  components: {
    Form,
    Field,
  },
  props: {
    identification: {
      type: String,
      required: true,
    },
  },
  emits: ['set-access-granted'],
  setup(props, { emit }) {
    const documentNumber = computed<string>(() =>
      String(props.identification).trim()
    );

    const validateAccess = (form: ValidateAccessFormI) => {
      const identification = String(form.identification).trim();

      emit('set-access-granted', identification === documentNumber.value);
    };

    return {
      validateAccess,
      schemaCouponValidationForm,
    };
  },
});
