import { object, string } from 'yup';

export const schemaCouponValidationForm = object().shape({
  identification: string()
    .trim()
    .matches(/^(\d{4,10})*$/, 'El número de cédula debe contener solo números.')
    .min(4, 'Mínimo 4 caracteres.')
    .max(10, 'Máximo 10 caracteres.')
    .required('El número de cédula es requerido.'),
});
